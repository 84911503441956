import {
	ImageStyled,
	LinkStyled,
	Root,
	Title,
} from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-configurator/banner-configurator.styled'
import { useTrackingsActions } from '@bluheadless/ui-logic/src/hooks/trackings'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { cx } from '@emotion/css'
import { Fragment } from 'react'
import { useIntl } from 'react-intl'

const BannerConfigurator = ({
	className,
	contentType,
	cssProp,
	image,
	imageConfig,
	minHeight,
	RichContentComponent,
	seoBannerCreative,
	seoBannerId,
	seoBannerName,
	seoBannerPosition,
	sx,
	title: _title,
	titleColor,
	titleColorMobile,
	titleTag,
}) => {
	const { promotionClick } = useTrackingsActions()
	const { formatMessage } = useIntl()

	const trackClickEvent = (args) => {
		promotionClick({ ...args })
	}

	const hasImage = !!image?.mobile?.src

	if (!hasImage) {
		console.warn(`[RichContent] PageBuilder "${contentType}" has no image`)
	}

	const title = _title ? _title : formatMessage({ id: 'configurator_link_title' })

	const {
		siteName,
		projectSpecific: {
			configurator: { path: configuratorPath },
		},
	} = useConfig()

	return (
		<Root sx={{ ...sx, minHeight }} css={cssProp} className={cx('Magento-PageBuilder-Banner-Configurator', className)}>
			<LinkStyled
				href={configuratorPath}
				underline="hover"
				onClick={(e) => {
					if (seoBannerId && seoBannerName && seoBannerCreative && seoBannerPosition) {
						trackClickEvent({
							event: e,
							id: seoBannerId,
							name: seoBannerName,
							creative: seoBannerCreative,
							position: seoBannerPosition,
						})
					}
				}}
				sx={{ color: { xs: titleColorMobile, md: titleColor } }}
			>
				<ImageStyled
					src={image?.mobile?.src}
					alt={title + ' | ' + siteName}
					layout="fixed"
					width={66}
					height={66}
					{...imageConfig}
				/>
				{RichContentComponent ? (
					<Title component={titleTag} variant="body3">
						<RichContentComponent content={title} component={Fragment} />
					</Title>
				) : null}
			</LinkStyled>
		</Root>
	)
}

export default BannerConfigurator
