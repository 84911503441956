import Typography from '@bluheadless/ui/src/atoms/typography'
import Link from '@bluheadless/ui/src/molecules/link'
import Image from '@bluheadless/ui/src/particles/image'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'

export const Root = styled(Box)`
	--position-top: 60%;
	position: fixed;
	top: var(--position-top);
	right: 0;
	transform: translateY(calc(var(--position-top) * -1));
	z-index: 98;
	background-color: var(--color-black);
	padding: calc(var(--spacing-1) * 2.5) calc(var(--spacing-1) * 1.5) !important;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;

	${({ theme }) => theme.breakpoints.up('md')} {
		--position-top: 50%;
	}
`

export const LinkStyled = styled(Link)`
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

export const ImageStyled = styled(Image)`
	display: block;
	margin: 0 auto;
`

export const Title = styled(Typography)`
	text-align: center;
	text-transform: uppercase;
	margin-top: var(--spacing-1);
	color: inherit;
`
